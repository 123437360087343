import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {EventBus} from "@/helpers/EventBus";
import './app.css'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import i18n from './i18n'



const language = localStorage.getItem('lang');
if (language) {
  i18n.locale = language;
}

Vue.prototype.$eventBus = EventBus;
Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.use(vueNumeralFilterInstaller);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
