<template>
    <div id="chart">
        <apexchart type="line" height="450" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";

    export default {
        name: "ApexLine",
        components: {
            apexchart: VueApexCharts
        },
        props: {
            series: {
                type: Array,
                required: true,
                default: function () {
                    return [{
                        name: "Premium",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }]
                }
            },
            chartTitle:{
                type:Object,
                required: false,
                default:function () {
                    return {
                        text: '',
                        align: 'left'
                    }
                }
            }
        },
        data: () => ({


            chartOptions: {
                chart: {
                    height: 450,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                colors: ['#fc5056', '#f59c0b', '#15b11d', '#546E7A', '#FF9800'],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'top',
                    background: {
                        enabled: true,
                    },
                    // eslint-disable-next-line
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                stroke: {
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,
                },
                title: {
                    text: 'Monthly Premium',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                        }


                    }
                },
                tooltip: {
                    y: {
                        formatter: function (value,context) {
                            // console.log(context)
                            // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            var index = context.dataPointIndex
                            var previousValue = context.series[context.seriesIndex][index - 1] || context.series[context.seriesIndex][index]
                            if(previousValue==0 || isNaN(previousValue)){
                                return 0 + ' (0%)'
                            }
                            var growthPercentage = ((value - previousValue) / previousValue) * 100


                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +'('+ growthPercentage.toFixed(0)+'%)';

                        }

                    }
                }
            },


        }),


        watch: {
            chartTitle: {
                immediate: true,
                handler(newTitle) {
                    this.chartOptions.title = newTitle;
                }
            },

        },

    }
</script>

<style scoped>

</style>