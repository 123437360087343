<template>
    <v-app>
        <v-navigation-drawer
                v-model="drawer"
                app
                dark
                width="200"
                color="#212121"

        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h4">
                        RF- ANALYSIS
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item to="/admin" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('MAIN.DASH')}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list
                    dark
                    nav
                    v-for="(row,index) in items"
                    :key="index"
            >
                <v-subheader style="height: 25px;">
                    {{ row.group_tile}}
                </v-subheader>

                <v-list-item
                        v-for="item in row.group_item"
                        :key="item.title"
                        :to="item.to"
                        dense


                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
            <!--  -->
        </v-navigation-drawer>

        <v-app-bar app dark color="green darken-4" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{$t('COMPANY_NAME')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text value="en" @click="$store.dispatch('setLang','en')">EN</v-btn>
            <v-btn text value="la" @click="$store.dispatch('setLang','la')">ລາວ</v-btn>
            <v-btn text value="th" @click="$store.dispatch('setLang','th')">ไทย</v-btn>
            <v-divider vertical class="mx-2"></v-divider>
            <v-btn icon @click="logout">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <loader-component></loader-component>
        <action-status></action-status>
        <v-main class="ml-2 pr-2 blue-grey grey lighten-4">
            <v-btn
                    color="error"
                    fab
                    dark
                    small
                    fixed
                    bottom
                    right
                    mb-8
                    @click="update"
            >
                <v-icon>mdi-monitor-arrow-down-variant</v-icon>
            </v-btn>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
    import ActionStatus from "@/components/actionStatus";
    import LoaderComponent from "@/components/Loader";

    export default {
        name: "ansLayout",
        components: {LoaderComponent, ActionStatus},
        data: () => ({
            drawer: null,

            form_rules: {
                required_rule: [
                    v => !!v || 'required'
                ],
                email_valid: [
                    v => !!v || 'required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                ]
            }
        }),
        computed: {
            items() {
                return [
                    {
                        "group_tile": 'POLICY ANALYSIS',
                        "group_item": [
                            {title: 'Policy Status', icon: 'mdi-list-status', to: '/ans/plc-state'},
                            {title: 'Client Analysis', icon: 'mdi-table-account', to: '/ans/client'},
                            {title: 'Business Grow', icon: 'mdi-chart-waterfall', to: '/ans/grow'},
                            // {title: 'Current Benefit', icon: 'mdi-currency-usd', to: '/ans/benefit'},

                        ]

                    },
                    {
                        "group_tile": 'AGENT ANALYSIS',
                        "group_item": [
                            {title: 'Agent Grow', icon: 'mdi-stairs-up', to: '/ans/agent-grow'},
                            {title: 'Agent Rank', icon: 'mdi-chevron-triple-up', to: '/ans/agent-rank'},
                            {title: 'Team Rank', icon: 'mdi-account-group', to: '/ans/team-rank'},

                        ]

                    },
                    {
                        "group_tile": 'Forecast (AI)',
                        "group_item": [
                            {title: 'Agent Grow', icon: 'mdi-stairs-up', to: '/ans/ai/agent-grow'},
                            {title: 'Premium grow', icon: 'mdi-chevron-triple-up', to: '/ans/ai/premium-grow'},

                        ]

                    },


                ]
            },
            currentLang(){
                return  this.$store.state.lang
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')

            },
            update(){
                location.reload(true)
            }
        }
    }
</script>

<style scoped>

</style>