<template>
    <v-app>
        <v-navigation-drawer
                v-model="drawer"
                app
                dark
                width="200"
                color="#212121"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        EZL PROJECT
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item to="/frontend" :exact="true" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        ໜ້າຫຼັກ
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list
                    dark
                    nav
                    v-for="(row,index) in items"
                    :key="index"
            >


                <v-list-item
                        v-for="item in row.group_item"
                        :key="item.title"
                        :to="item.to"

                        dense


                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
            <v-divider></v-divider>
            <v-list nav>
                <v-list-item to="/frontend/security" :exact="true" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        ປ່ຽນລະຫັດຜ່ານ
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dark>
                <v-subheader>
                    ຂໍ້ມູນເພີ່ມເຕີມ
                </v-subheader>
                <v-list-item to="/frontend/comm" :exact="true" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-sack-percent</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        ຄ່ານາຍໜ້າ
                    </v-list-item-content>
                </v-list-item>
                <v-list-item target="_blank" href="https://referral.apaeasy.com/api/assets/uploads/EZL_BROCHURE.pdf"  dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-umbrella-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        ຜະລິດຕະພັນ
                    </v-list-item-content>
                </v-list-item>
                <v-list-item target="_blank" href="https://referral.apaeasy.com/api/assets/uploads/EZL_FORM.pdf" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        ແບບຟອມຊື້ປະກັນໄພ
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!--  -->
        </v-navigation-drawer>

        <v-app-bar app dark color="green darken-4" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{$t('COMPANY_NAME')}}</v-toolbar-title>
            <v-spacer></v-spacer>
<!--            <v-btn text value="en" @click="$store.dispatch('setLang','en')">EN</v-btn>-->
<!--            <v-btn text value="la" @click="$store.dispatch('setLang','la')">ລາວ</v-btn>-->
<!--            <v-btn text value="th" @click="$store.dispatch('setLang','th')">ไทย</v-btn>-->
            <v-divider vertical class="mx-2"></v-divider>
            <v-btn icon @click="logout">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <loader-component></loader-component>
        <action-status></action-status>
        <v-main class="ml-2 pr-2 blue-grey grey lighten-4">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
    import ActionStatus from "@/components/actionStatus";
    import LoaderComponent from "@/components/Loader";

    export default {
        name: "agentLayout",
        components: {LoaderComponent, ActionStatus},
        data: () => ({
            drawer: null,

            form_rules: {
                required_rule: [
                    v => !!v || 'required'
                ],
                email_valid: [
                    v => !!v || 'required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                ]
            }
        }),
        computed: {
            items() {
                return [
                    {
                        "group_tile": 'ຈັດການ',
                        "group_item": [
                            {title: 'ທີມງານຂອງເຮົາ', icon: 'mdi-account-group', to: '/frontend/team'},
                            {title: 'ຕິດຕາມສັນຍາ', icon: 'mdi-clock-alert-outline', to: '/frontend/follow'},
                            {title: 'ລາຍງານການຂາຍ', icon: 'mdi-finance', to: '/frontend/sale'},
                            {title: 'ລາຍງານລາຍຮັບ', icon: 'mdi-sack-percent', to: '/frontend/commission'},

                        ]

                    },


                ]
            },
            currentLang(){
                return  this.$store.state.lang
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')

            },
        }
    }
</script>

<style scoped>

</style>