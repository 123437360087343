import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import adminLayout from "@/views/adminLayout";
import LoginView from "@/views/LoginView";
import agentLayout from "@/views/agentLayout";
import store from '@/store'
import ansLayout from "@/views/ansLayout";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/admin'
  },
  {
    path: '/admin',
    name: 'home',
    meta:{layout:adminLayout,auth:true},
    component: HomeView
  },
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/agent',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/listView')
  },
  {
    path: '/agent/create',
    name:'createagent',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/createView')
  },
  {
    path: '/agent/update/:id',
    name:'updateagent',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/updateView')
  }
  ,
  {
    path: '/agent/view/:id',
    name:'viewagent',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/detailView')
  },

  {
    path: '/agent/request',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/requestView')
  },
  {
    path: '/agent/request/accept/:id',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/acceptRequest')
  },
  {
    path: '/agent/leader',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/teamView')
  },
  {
    path: '/agent/um',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/agent/managerView')
  },

  {
    path: '/setcom',
    name:'setcom',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/comm/comSetting')
  },

  //policy

  {
    path: '/policy/create',
    name:'newpolicy',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/policy/createView')
  },
  {
    path: '/policy/renew',
    name:'renewpolicy',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/policy/renewView')
  }
  ,
  {
    path: '/report/ezl',
    name:'salereport',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/report/saleReport')
  },
  {
    path: '/report/pa',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/report/paReport')
  }
  ,
  {
    path: '/report/rank',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/report/rankView')
  }
  ,
  {
    path: '/report/ezlcom',
    name:'comreport',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/report/comReport')
  }
  ,
  {
    path: '/report/pacom',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/report/comPa')
  },
  {
    path: '/policy/expiry',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/policy/expiryList')
  },
  {
    path: '/policy/search',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/policy/searchView')
  },
  {
    path: '/policy/card',
    meta:{layout:adminLayout,auth:true},
    component: () => import('../views/admin/policy/cardView')
  },
  {
    path: '/register',
    component: () => import('../views/registerView')
  },
    //frontend section
  {
    path: '/frontend',
    exact:true,
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/homeView')
  },
  {
    path: '/frontend/team',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/teamView')
  },
  {
    path: '/frontend/sale',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/saleReport')
  },
  {
    path: '/frontend/commission',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/comReport')
  },
  {
    path: '/frontend/follow',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/followView')
  },
  {
    path: '/frontend/comm',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/comSetting')
  },
  {
    path: '/frontend/security',
    meta:{layout:agentLayout,auth:true},
    component: () => import('@/views/user/securityView')
  },
    //end frontend


    //region Analysis
  {
    path: '/ans/plc-state',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/policyStatus')
  },
  {
    path: '/ans/client',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/clientView')
  },
  {
    path: '/ans/grow',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/growView')
  },
  {
    path: '/ans/agent-rank',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/admin/report/rankView')
  },
  {
    path: '/ans/agent-grow',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/agentGrow')
  },
  {
    path: '/ans/team-rank',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/teamPremium')
  },

  {
    path: '/ans/ai/agent-grow',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/ai/agent-grow-ai')
  },
  {
    path: '/ans/ai/premium-grow',
    meta:{layout:ansLayout,auth:true},
    component: () => import('@/views/ans/ai/premium-grow-ai')
  },

  //endregion Analysis
    //region EZY GOLD
  {
    path: '/ezg/create',
    meta:{layout:adminLayout,auth:true},
    component: () => import('@/views/admin/ezg/createPolicy')
  },
  {
    path: '/report/ezg',
    meta:{layout:adminLayout,auth:true},
    component: () => import('@/views/admin/report/ezgReport')
  },
  {
    path: '/report/ezgcom',
    meta:{layout:adminLayout,auth:true},
    component: () => import('@/views/admin/report/comEzg')
  },
  {
    path: '/ezg/renew',
    meta:{layout:adminLayout,auth:true},
    component: () => import('@/views/admin/ezg/renewView')
  },
  {
    path: '/policy/transfer',
    meta:{layout:adminLayout,auth:true},
    component: () => import('@/views/admin/policy/transferView')
  },

    //endregion
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
