<template>
    <v-app>
        <v-navigation-drawer
                v-model="drawer"
                app
                dark
                width="200"
                color="#212121"

        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h4">
                        REFERRAL
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item to="/admin" dense>
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('MAIN.DASH')}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-expansion-panels accordion hover tile >
                <v-expansion-panel
                        v-for="(item,i) in items"
                        :key="i"
                >
                    <v-expansion-panel-header>
                        {{item.group_tile}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content >
                        <v-list
                                dense
                                dark
                                nav

                        >
                            <v-list-item
                                    v-for="row in item.group_item"
                                    :key="row.title"
                                    :to="row.to"
                                    active-class="warning"
                                    dense
                            >
                                <v-list-item-icon class="mr-3">
                                    <v-icon>{{ row.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(row.title) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-list
                    dark
                    nav
            >
                <v-subheader style="height: 25px;">
                    <v-divider></v-divider>
                </v-subheader>

                <v-list-item
                        dense
                        to="/ans/plc-state"

                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-arrow-right-bold-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>ANALYSIS</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
            <!--  -->
        </v-navigation-drawer>

        <v-app-bar app dark color="green darken-4" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{$t('COMPANY_NAME')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text color="error"  @click="update"><v-icon>mdi-progress-download</v-icon></v-btn>
            <v-btn text value="en" @click="$store.dispatch('setLang','en')">EN</v-btn>
            <v-btn text value="la" @click="$store.dispatch('setLang','la')">ລາວ</v-btn>
<!--            <v-btn text value="th" @click="$store.dispatch('setLang','th')">ไทย</v-btn>-->
            <v-divider vertical class="mx-2"></v-divider>
            <v-btn icon @click="logout">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <loader-component></loader-component>
        <action-status></action-status>
        <v-main class="ml-2 pr-2 blue-grey grey lighten-4">

           <router-view/>
        </v-main>

    </v-app>
</template>

<script>
    import ActionStatus from "@/components/actionStatus";
    import LoaderComponent from "@/components/Loader";

    export default {
        name: "adminLayout",
        components: {LoaderComponent, ActionStatus},
        data: () => ({
            drawer: null,

            form_rules: {
                required_rule: [
                    v => !!v || 'required'
                ],
                email_valid: [
                    v => !!v || 'required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                ]
            }
        }),
        computed: {
            items() {
                return [
                    {
                        "group_tile": this.$t('MAIN.MANAGE'),
                        "group_item": [
                            {title: 'MAIN.MN_AGENT', icon: 'mdi-face-agent', to: '/agent'},
                            // {title: 'Wallet', icon: 'mdi-wallet', to: '/wallet'},
                            {title: 'MAIN.MN_COMM', icon: 'mdi-progress-wrench', to: '/setcom'},

                        ]

                    },
                    {
                        "group_tile": this.$t('MAIN.BUSINESS'),
                        "group_item": [
                            {title: 'MAIN.NEW_POLICY', icon: 'mdi-file-document-edit-outline', to: '/policy/create'},
                            {title: 'EZG.NEW_EZG_POLICY', icon: 'mdi-file-certificate-outline', to: '/ezg/create'},


                        ]

                    }
                    ,
                    {
                        "group_tile": this.$t('MAIN.RENEWAL'),
                        "group_item": [
                            {title: 'MAIN.RENEW_POLICY', icon: 'mdi-autorenew', to: '/policy/renew'},
                            {title: 'EZG.EZG_RENEW', icon: 'mdi-autorenew', to: '/ezg/renew'},

                        ]

                    }
                    ,
                    {
                        "group_tile": this.$t('MAIN.CHECK'),
                        "group_item": [
                            {title: 'MAIN.CHECK_EXPIRY', icon: 'mdi-timer-alert-outline', to: '/policy/expiry'},
                            {title: 'MAIN.POLICY_SEARCH', icon: 'mdi-magnify', to: '/policy/search'},
                            {title: 'MAIN.TRANSFER', icon: 'mdi-transfer', to: '/policy/transfer'},
                            {title: 'MAIN.PRINT_CARD', icon: 'mdi-card-account-details-outline', to: '/policy/card'},

                        ]

                    }
                    ,
                    {
                        "group_tile": this.$t('MAIN.REPORT'),
                        "group_item": [
                            {title: 'MAIN.EZL_REPORT', icon: 'mdi-finance', to: '/report/ezl'},
                            {title: 'EZG.REPORT_EZG_POLICY', icon: 'mdi-finance', to: '/report/ezg'},
                            {title: 'MAIN.PA_REPORT', icon: 'mdi-finance', to: '/report/pa'},


                        ]

                    },
                    {
                        "group_tile": this.$t('MAIN.COM_REPORT'),
                        "group_item": [
                            {title: 'MAIN.EZL_REPORT', icon: 'mdi-sack-percent', to: '/report/ezlcom'},
                            {title: 'EZG.EZG_REPORT', icon: 'mdi-sack-percent', to: '/report/ezgcom'},
                            {title: 'MAIN.PA_REPORT', icon: 'mdi-sack-percent', to: '/report/pacom'},

                        ]

                    }

                ]
            },
            currentLang(){
               return  this.$store.state.lang
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')

            },

            update(){
                location.reload(true)
            }
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding: 0 !important;

    }
</style>